<template>
  <v-col lg="4" sm="6" xs="12" md="6" cols="12">
    <Transaction v-if="showWidget('Transactions')" />
    <Profile v-if="showWidget('Profile')" />
    <Custom v-if="showWidget('Custom')" :url="url" :url_title="url_title" :title="title" :html="html"
      :image_url="image_url" />
  </v-col>
</template>

<script>

export default {
  props: ["position", "widget_name", "title", "url", "url_title", "html", "image_url"],
  components: {
    Transaction : () => import('./widgets/Transactions.vue'),
    Profile : () => import('./widgets/Profile.vue'),
    Custom : () => import('./widgets/Custom.vue'),
  },

  methods: {
    showWidget(widget_name) {
      if (this.position == widget_name) {
        return true;
      }

      return false;
    },
  },
  computed: {
  },
};
</script>

<style>
</style>
