<template>
  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date"
    transition="scale-transition" offset-y min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field dense outlined :value="computedDateFormatted" label="Datum" prepend-icon="mdi-calendar" disabled
        v-bind="attrs" v-on="on">
      </v-text-field>
    </template>
    <v-date-picker v-model="date" no-title scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">
        Cancel
      </v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(date)">
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";
export default {
  props: ["value", "label", "disabled"],
  data() {
    return {
      menu: false,
      required: (value) => !!value || this.$t("mandatory"),
      ruleValidDate: (value) =>
        !value || this.dateNotValid || this.$t("dateNotValid"),
      date: null,
    };
  },
  created() {
    this.date = this.value;
  },
  methods: {
    formatDate(date) {
      if (moment(date).isValid()) {
        return moment(date).format("DD-MM-YYYY");
      }

      return "-";
    },
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date)
    },
    dateNotValid() {
      if (this.date.length == 10 && moment(this.date, "DD-MM-YYYY").isValid()) {
        return true;
      }

      return false;
    },
  },
  watch: {
    value() {
      if (!this.date) {
        let date = moment(this.value);
        if (date.isValid()) {
          this.date = date.format("DD-MM-YYYY");
        }
      }
    },
    date() {
      this.$emit("input", this.date);
    },
  },
};
</script>

<style>

</style>
