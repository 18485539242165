<template>
    <v-main>

        <title-header>
            <template v-slot:buttons>
                <template v-if="payload.service_id">
                    <v-chip v-mask="'##.##'" large :color="getBalance.color" class="ma-2">
                        {{ $t('balance') }}: <strong class="ml-1">{{ round(getBalance.balance) }} {{
                            selectedServiceUnit()
                            }}</strong>
                    </v-chip>
                </template>

                <v-btn rounded :disabled="!valid || bookdates.length == 0" class="ml-2" @click="save()" color="primary">
                    <span v-if="$vuetify.breakpoint.mdAndUp">{{ $t("save") }}</span>
                    <v-icon :right="$vuetify.breakpoint.mdAndUp">mdi-content-save</v-icon>
                </v-btn>
            </template>
        </title-header>

        <v-row>
            <v-col cols="12">
                <v-card :rounded="$config.shaped_widgets" class="pa-lg-5">
                    <v-card-title>
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" lg="6" md="6">

                                <span> {{ $t('createTransactionInfo') }} </span>

                                <v-text-field class="mt-5" @change="setSearch()" @keydown.enter="manualSearch()"
                                    :hide-no-data="!search" :search-input.sync="search" v-model="payload.accesstoken"
                                    :items="accesstokens" :label="$t('searchOnCardNumber')"
                                    prepend-inner-icon="mdi-magnify" append-icon="mdi-magnify" outlined dense clearable>
                                </v-text-field>


                                <v-select :loading="loading"
                                    :disabled="payload.accesstoken && payload.accesstoken.length < 19"
                                    :label="$t('type')" outlined dense v-model="payload.service_id" item-text="name"
                                    item-value="id" :items="services" clearable></v-select>

                                <v-date-picker :first-day-of-week="1" :disabled="!payload.service_id" full-width
                                    multiple v-model="bookdates">
                                </v-date-picker>

                            </v-col>
                            <v-divider class="mx-4" v-if="$vuetify.breakpoint.mdAndUp" vertical></v-divider>
                            <v-divider v-else></v-divider>
                            <v-col>

                                <v-form v-model="valid" ref="transaction_form">
                                    <v-row v-for="(transaction, index) in sortedTransactions" :key="index">
                                        <v-col>
                                            <v-text-field prepend-icon="mdi-calendar" filled readonly
                                                :label="$t('date')" :value="formatDate(transaction.invoiceAfter)"
                                                outlined dense>
                                            </v-text-field>
                                        </v-col>

                                        <v-col>
                                            <v-text-field :label="selectedServiceUnit()" v-model="transaction.amount"
                                                outlined dense :rules="[nocommas, required]"
                                                placeholder="af te schrijven tegoed" hint="Bijvoorbeeld: 12.15">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-spacer></v-spacer>
                                            <v-btn @click="deleteRow(transaction.invoiceAfter)" text color="grey">
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>

                                        </v-col>
                                    </v-row>
                                </v-form>
                                <v-btn :loading="loading" rounded :disabled="!valid || bookdates.length == 0"
                                    v-if="$vuetify.breakpoint.smAndDown" @click="save()" block color="primary">
                                    <span>{{ $t("save") }}</span>
                                    <v-icon right>mdi-content-save</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-main>
</template>

<script>

import moment from 'moment'

export default {
    components: {

    },
    name: "CreateTransaction",
    data() {
        return {
            menu: false,
            loading: false,
            show: false,
            participant_services: [],
            loading_address: false,
            services: [],
            payload: {
                accesstoken: null,
                balancedefinition_id: null,
                sum: 0,
                transactions: [],
                service_id: null
            },
            bookdates: null,
            required: (value) => value > 0 || this.$t("mandatory"),
            nocommas: (value) => !value.includes(",") || 'Geen komma\'s gebruiken. Gebruik een punt.',
            only_numbers: (value) => /^-?\d+\.?\d*$/.test(value) || this.$t("onlyNumbers"),
            valid: false,
            accesstokens: [],
            search: null,
        };
    },
    created() {
        this.getTransactions();
        if (this.$route.query.accesstoken) {
            this.payload.accesstoken = this.$route.query.accesstoken;
            this.setSearch();
        }
    },

    methods: {
        manualSearch() {
            console.log(this.payload.accesstoken);
            if (this.payload.accesstoken && this.payload.accesstoken.length == 19) {
                this.setSearch();
                this.getServices();
            }
        },
        setSearch() {
            this.$store.dispatch('setSearch', this.payload.accesstoken)
            this
        },
        getTransactions() {
            this.loading = true;
            this.$http
                .get(this.$config.backend_url + "/partner-transactions")
                .then((response) => {
                    this.transactions = response.data;
                    this.$store.dispatch("setTransactions", this.transactions);
                    this.loading = false;
                }).catch(error => {
                    this.$toast.error(error.response.data.message)
                    this.loading = false;
                })
        },
        deleteRow(bookdate) {
            this.payload.transactions = this.payload.transactions.filter((transaction) => {
                return transaction.bookdate != bookdate
            })
            this.bookdates = this.bookdates.filter((date) => {
                return date != bookdate
            })
        },
        formatDate(date) {
            if (moment(date).isValid()) {
                return moment(date).format("DD-MM-YYYY");
            }

            return "-";
        },
        selectedServiceName() {
            if (this.payload.balancedefinition_id) {
                let service = this.services.find(service => service.id == this.payload.balancedefinition_id)
                return service.name.charAt(0).toUpperCase() + service.name.slice(1);
            }
            return ''
        },
        selectedServiceUnit() {
            if (this.payload.balancedefinition_id) {
                let service = this.services.find(service => service.id == this.payload.balancedefinition_id)
                return service.unit.charAt(0).toUpperCase() + service.unit.slice(1);
            }
            return ''
        },
        getServices() {
            this.loading = true
            this.$http
                .get(this.$config.backend_url + "/partner-accesstokens?accesstoken=" + this.payload.accesstoken)
                .then((response) => {

                    this.services = []

                    response.data.services.forEach((service) => {
                        service.wallets.forEach((wallet) => {
                            this.services.push({
                                id: wallet.budgetId,
                                balancedefinition_id: wallet.budgetId,
                                name: wallet.name + ' (' + this.round(wallet.amount) + ' ' + service.unit + ')',
                                name_short: wallet.name,
                                unit: service.unit,
                                balance: parseFloat(wallet.amount)
                            })
                        })
                    })
                })
                .catch((error) => {
                    this.loading = false;
                    this.$toast.error(error.response.data.message)
                    this.services = []
                });
        },
        save() {
            this.$refs.transaction_form.validate()

            if (!this.valid) {
                return false
            }

            let sum = parseFloat(0)

            this.payload.transactions.forEach(transaction => {
                sum += parseFloat(transaction.amount)
            });

            this.payload.sum = sum
            let selected_service = this.services.find(service => service.id == this.payload.service_id)

            if (sum > selected_service.balance) {
                this.$toast.error(this.$t("notEnoughBalance"));
                return false
            }

            this.loading = true;

            let new_payload = {
                accesstoken: this.payload.accesstoken,
                transactions: this.payload.transactions.map(transaction => {
                    return {
                        ...transaction,
                        invoiceAfter: new Date(transaction.invoiceAfter).toISOString()
                    }
                })
            }

            this.$http
                .post(this.$config.backend_url + "/partner-transactions", new_payload)
                .then(() => {
                    this.loading = false;
                    this.$toast.success(this.$t("saved"));
                    this.$router.push({ name: "transactions", query: { accesstoken: this.payload.accesstoken } });
                })
                .catch(() => {
                    this.loading = false;
                    this.$toast.error(this.$t("somethingWentWrong"));
                });
        },
        round(value) {
            let parsed = parseFloat(value)
            return Math.round(parsed * 100) / 100
        },
        reset() {
            this.bookdates = []
            this.$refs.transaction_form.reset()
            this.payload.transactions = []
            this.payload.sum = 0
            this.balancedefinition_id = null
            this.getServices()
        }
    },
    computed: {
        sortedTransactions() {
            let transactions = this.payload.transactions

            let sorted = transactions.sort((a, b) => {
                return new Date(a.invoiceAfter) - new Date(b.invoiceAfter);
            });

            return sorted;
        },
        getBalance() {
            let service = this.services.find(service => service.id == this.payload.service_id)
            let balance = 0
            if (service) {
                balance = service.balance
            }

            let total_booked = 0

            this.payload.transactions.forEach(transaction => {
                total_booked += transaction.amount ? parseFloat(transaction.amount) : 0
            })

            let balance_amount = balance - total_booked

            if (isNaN(balance_amount)) {
                balance_amount = 0
            }

            return {
                balance: balance_amount,
                color: balance_amount <= 0 ? 'error' : 'success'
            }
        },
    },
    watch: {
        "payload.accesstoken": {
            handler() {
                if (this.payload.accesstoken.length == 19) {
                    this.getServices()
                } else {
                    this.services == []
                    this.payload.balancedefinition_id = null
                }
            },
            deep: true,
            immediate: true
        },
        bookdates: {
            handler() {
                let service = this.services.find(service => service.id == this.payload.service_id)

                this.bookdates.forEach(bookdate => {
                    // if transaction does not exist, create it
                    if (!this.payload.transactions.find(transaction => transaction.invoiceAfter == bookdate)) {

                        this.payload.transactions.push({
                            invoiceAfter: bookdate,
                            amount: null,
                            token: this.payload.accesstoken,
                            type: "PAYMENT",
                            unit: service.unit,
                            eanCode: service.name_short
                        })
                    }
                })

                // remove transactions that are not in bookdates
                this.payload.transactions = this.payload.transactions.filter((transaction) => {
                    return this.bookdates.find(bookdate => bookdate == transaction.invoiceAfter)
                })

            },
            deep: true
        }
    }
};
</script>

<style lang="scss">
.uppercase input {
    text-transform: uppercase;
}
</style>
