export default [
    {
        title: "dashboard",
        link: "/dashboard",
        icon: "mdi-view-dashboard",
        meta: null,
    },
    {
        title: "transactions",
        link: "/transactions",
        icon: "mdi-ballot",
        meta: null,
    },
    {
        title: "profile",
        link: "/profile",
        icon: "mdi-account",
        meta: null,
    },
];
