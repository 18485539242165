<template>
  <div>
    <Transition name="fade">
      <blank v-if="
  $route.name == 'login' ||
  $route.name == 'password-reset' ||
  $route.name == 'change-password' ||
  $route.name == 'forgot-username' ||
  $route.name == 'mfa-setup' ||
  $route.name == 'mfa-verify' ||
  $route.name == 'offline'
      " />
      <full v-else />
    </Transition>
  </div>
</template>

<script>
import Blank from "./layout/Blank.vue";
import Full from "./layout/Full.vue";
import "./style.scss";

export default {
  components: { Blank, Full },
  name: "App",
  async mounted() {
    this.$i18n.locale = this.$store.getters.language;
    this.updateTranslations();
  },
  methods: {
    updateTranslations() {
      let url =
        process.env.VUE_APP_BACKEND_URL + "/translations"
      this.$http.get(url, {
        headers: {
          "x-portal-instance-key": process.env.VUE_APP_INSTANCE_KEY,
        },
      })
        .then((response) => {
          if (response.data) {
            this.$store.dispatch("setTranslations", response.data);
            this.$i18n.setLocaleMessage("nl", response.data.nl);
            this.$i18n.setLocaleMessage("en", response.data.en);
          }
        })
        .catch(() => {
          this.initializing = false;
          this.faulty_instance = true;
        });
    },
  },
  watch: {
    "$store.getters.getConfigVars": {
      handler(val, oldVal) {
        if (val.updated_at != oldVal.updated_at) {
          window.location.reload();
        }
      },
    }
  }
};
</script>

<style>

</style>
