<template >

    <div class="middle-align">
        <v-btn text @click="$router.back()">
            <v-icon left>mdi-arrow-left</v-icon>
        </v-btn>

        <span class="text-h5">
            {{ $t($route.meta.title) }}
        </span>

        <v-spacer></v-spacer>
        <slot name="buttons"></slot>

    </div>
</template>
<script>
export default {

}
</script>
<style lang="scss">
.middle-align {
    width: 100% !important;
    display: flex;
    height: 64px;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
}
</style>
