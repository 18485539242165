<template>
  <div class="d-block text-center justify-center mt-8">
    <div class="spin-loader"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.spin-loader {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.spin-loader:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #757575;
  border-color: #757575 transparent #757575 transparent;
  animation: spin-loader 1.2s linear infinite;
}

@keyframes spin-loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
