<template>
  <div>
    <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon small>mdi-delete</v-icon>
        </v-btn>

      </template>

      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>


          <v-btn @click="counterBook()" color="primary" text>
            Transactie annuleren
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>

</template>
<script>
export default {
  props: ['transaction'],
  data() {
    return {
      menu: false,
    }
  },
  methods: {
    counterBook() {
      this.$http
        .post(this.$config.backend_url + "/partner-transactions/cancel", {
          transaction_id: this.transaction.id
        })
        .then((response) => {
          this.loading = false;
          this.$toast.success('Transactie geannuleerd');
          this.$emit('refresh')
          this.menu = false
        })
        .catch((error) => {
          this.loading = false;
          this.$toast.error('Er is iets misgegaan, probeer het later opnieuw')
          this.menu = false
        });
    }
  }


}
</script>
<style lang="">

</style>
