<template>
  <div>
    <title-header>
      <template v-slot:buttons>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn text @click="exportExcel()" class="mr-2" color="primary" v-on="on">
              <v-icon left>mdi-file-excel</v-icon>
              Exporteren
            </v-btn>
          </template>
          <span>{{ $t('exportExcel') }}</span>
        </v-tooltip>

        <v-btn rounded color="primary"
          @click="$router.push({ name: 'transaction-create', query: { accesstoken: $store.getters.getSearch } })">
          {{ $t('add') }}
        </v-btn>
      </template>
    </title-header>
    <div v-if="services.length > 0" style="display: flex;" class="mb-2">
      <v-spacer></v-spacer>
      <!-- v-chip group -->
      <!-- <v-chip-group column>
        <v-chip color="secondary" large v-for="item in services" :key="item.id" :value="item.id">
          {{ item.name }}
        </v-chip>
      </v-chip-group> -->

    </div>
    <v-row>
      <v-col>
        <v-card :rounded="$config.shaped_widgets" class="pa-lg-5">
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="6">
                <v-text-field rounded v-model="search" :items="accesstokens" :label="$t('searchOnCardNumber')"
                  prepend-inner-icon="mdi-magnify" filled dense clearable hide-details>
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="12" lg="6">

                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="6">
                    <v-menu ref="menu_start" v-model="menu_start" :close-on-content-click="false" :nudge-right="40"
                      transition="scale-transition" offset-y min-width="290px">
                      <template v-slot:activator="{ on, attrs }">

                        <v-text-field filled dense clearable hide-details rounded v-model="startDate"
                          label="Boeking datum start" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs"
                          v-on="on">
                        </v-text-field>
                      </template>

                      <v-date-picker v-model="start_date" no-title scrollable locale="nl-nl">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu_start = false">
                          Annuleren
                        </v-btn>
                        <v-btn text color="primary" @click="$refs.menu_start.save(start_date)">
                          {{ $t('ok') }}
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="6">
                    <v-menu ref="menu_end" v-model="menu_end" :close-on-content-click="false" :nudge-right="40"
                      transition="scale-transition" offset-y min-width="290px">
                      <template v-slot:activator="{ on, attrs }">

                        <v-text-field filled dense clearable hide-details rounded v-model="endDate"
                          label="Boeking datum einde" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs"
                          v-on="on">
                        </v-text-field>
                      </template>


                      <v-date-picker v-model="end_date" no-title scrollable locale="nl-nl">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu_end = false">
                          Annuleren
                        </v-btn>
                        <v-btn text color="primary" @click="$refs.menu_end.save(end_date)">
                          {{ $t('ok') }}
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>


            <v-data-table class="mt-5" :no-data-text="$t('fillACardNumber')" :loading="loading" :headers="headers"
              :items="filtered" sort-by="bookdate" :options.sync="options" :items-per-page.sync="options.itemsPerPage"
              :footer-props="footer_props">

              <template v-slot:item.token="{ item }">
                {{ item.token }}
              </template>

              <!-- amount -->
              <template v-slot:item.amount="{ item }">
                {{ formatUnit(item) }}
              </template>

              <template v-slot:item.invoiceAfter="{ item }">
                {{ formatDate(item.invoiceAfter) }}
              </template>

              <template v-slot:item.type="{ item }">
                {{ $t(item.type) }}
              </template>

              <!-- status -->
              <template v-slot:item.status="{ item }">
                {{ $t(item.status) }}
              </template>

              <template v-slot:item.actions="{ item }">
                <edit @refresh="getTransactions()" :transaction="item"></edit>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'
import Edit from './Edit.vue'

export default {
  name: "Transactions",
  components: {
    Edit
  },
  data() {
    return {
      menu_start: false,
      menu_end: false,
      loading: false,
      search: "",
      search_fields: {
        accesstoken: null,
        zipcode: null,
        housenumber: null,
        day: null,
        time: null,
        type: null
      },
      transactions: [],
      accesstokens: [],
      filter_options: [
        {
          name: "clientreference",
          label: this.$t("clientreference"),
          type: "text-field",
        },
      ],
      headers: [
        {
          text: this.$t("card"),
          align: "start",
          sortable: true,
          value: "token",
        },
        {
          text: this.$t("type"),
          align: "start",
          sortable: true,
          value: "type",
        },
        {
          text: this.$t("amount"),
          align: "start",
          sortable: true,
          value: "amount",
        },
        {
          text: this.$t("time"),
          align: "start",
          sortable: true,
          value: "invoiceAfter",
        },
        {
          text: this.$t("status"),
          align: "start",
          sortable: true,
          value: "status",
        },
        {
          text: this.$t("actions"),
          align: "end",
          sortable: true,
          value: "actions",
        },
      ],
      options: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 25,
        multiSort: false,
        mustSort: true,
        page: 1,
        sortBy: ['time'],
        sortDesc: ['time'],
      },
      footer_props: {
        itemsPerPageOptions: [5, 15, 25, 50, 100, 150],
        showFirstLastPage: true,
      },
      services: [],
      export_loading: false,
      start_date: null,
      end_date: null,
    };
  },
  created() {
    this.getTransactions();
    if (this.$route.query.accesstoken) {
      this.search = this.$route.query.accesstoken;
    }
  },
  methods: {
    searchJob() {
      this.getTransactions();
    },
    exportExcel() {
      let start_date = this.start_date;
      let end_date = this.end_date;

      if (start_date == null) {
        // get date of the first transaction
        start_date = this.transactions[0].bookdate;
      }
      if (end_date == null) {
        // get date of the last transaction
        end_date = this.transactions[this.transactions.length - 1].bookdate;
      }
      // if transaction count > 10000 then export in chunks
      if (this.transactions.length >= 10000) {
        this.$toast.error(this.$t("exportError"));
        return
      }

      if (this.transactions.length == 0) {
        this.$toast.error(this.$t("noTransactions"));
        return
      }

      this.export_loading = true;
      this.$http
        .get(this.$config.backend_url + "/partner-transactions/export?accesstoken=" + this.search + "&startdate=" + start_date + "&enddate=" + end_date, {
          responseType: 'blob'
        }
        )
        .then((response) => {
          // download the excel file in response.data
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link
            .setAttribute('href', url);
          link.setAttribute('download', 'transacties-' + this.search + '.xlsx');
          document.body.appendChild(link);
          link.click();


          this.export_loading = false;
        }).catch(error => {
          console.log(error)
          // this.$toast.error(error.response.data.message)
          this.export_loading = false;
        })


    },

    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    formatTime(date) {
      return moment(date).format("HH:mm");
    },
    formatType(item) {
      // if type is PAYMENT, UPGRADE, REDEEM

      if (item.type == "PAYMENT") {
        return this.$t("payment");
      }
      if (item.type == "UPGRADE") {
        return this.$t("upgrade");
      }
      if (item.type == "REDEEM") {
        return this.$t("redeem");
      }

      return item.type;
    },
    setSearch() {
      this.$store.dispatch('setSearch', this.search)
      if (!this.search) {
        this.services = []
      }
    },
    getTransactions() {
      this.loading = true;
      this.$http
        .get(this.$config.backend_url + "/partner-transactions")
        .then((response) => {
          this.transactions = response.data;
          this.$store.dispatch("setTransactions", this.transactions);
          if (this.search.length == 19) {
            this.getServices()
          }
          this.loading = false;
        }).catch(error => {
          // this.$toast.error(error.response.data.message)
          this.$toast.error('Er ging iets fout bij het ophalen van de transacties')
          this.loading = false;
        })
    },
    round(value) {
      let parsed = parseFloat(value)
      return Math.round(parsed * 100) / 100
    },
    getServices() {
      this.$http
        .get(this.$config.backend_url + "/partner-accesstokens?accesstoken=" + this.search)
        .then((response) => {
          this.services = []

          response.data.services.forEach((service) => {
            this.services.push({
              id: service.id,
              name: service.name + ' (' + this.round(service.balance) + ' ' + service.unit + ')',
            })
          })

          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast.error('Geen services gevonden voor deze kaart')
          this.services = []
        });
    },
    formatUnit(item) {
      let amount = parseFloat(item.amount)
      return amount
    },
  },
  computed: {
    // startdate with get and set
    startDate: {
      get() {
        // nl format
        if (this.start_date) {
          return new Date(this.start_date).toLocaleDateString("nl-NL");

        }
        else {
          return null
        }
      },
      set(value) {
        this.start_date = value;
      },
    },
    endDate: {
      get() {
        // nl format
        if (this.end_date) {
          return new Date(this.end_date).toLocaleDateString("nl-NL");

        }
        else {
          return null
        }
      },
      set(value) {
        this.end_date = value;
      },
    },
    filtered() {
      let transactions = this.transactions;

      // filter on start_date end end_date
      if (this.start_date && this.end_date) {
        transactions = transactions.filter((transaction) => {
          return moment(transaction.time).isBetween(this.start_date, this.end_date, null, '[]');
        });
      }
      // if startdate or enddate is null
      else if (this.start_date || this.end_date) {
        if (this.start_date) {
          transactions = transactions.filter((transaction) => {
            return moment(transaction.time).isSameOrAfter(this.start_date);
          });
        }
        if (this.end_date) {
          transactions = transactions.filter((transaction) => {
            return moment(transaction.time).isSameOrBefore(this.end_date);
          });
        }
      }

      if (this.search == "" || !this.search) {
        return transactions;
      }

      return transactions.filter((transaction) => {
        return transaction.token == this.search;
      });

    }
  },
  watch: {
    search: {
      handler(val) {
        if (val.length == 19) {
          this.getServices()
        }

        if (val.length < 19 || val == null) {
          this.services = []
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="scss"></style>
